import React from "react"

import { JobRole, JobRequirements, JobFeature } from "../components/job-components"
import Job from "../components/job"

const JobMachineLearningEngineer = () => {
	return (
		<Job
			title="Machine Learning and Computer Vision Engineer"
			location="Hybrid Office in Linz; Remote in Austria"
			workingTime="Full-time (38.5h)"
			startDate="ASAP"
			annualSalary="EUR 42,000"
		>
			<JobRole>
				<JobFeature>
					<strong>Develop machine learning models</strong> with state-of-the-art technologies
				</JobFeature>
				<JobFeature>
					<strong>Integrate</strong> machine learning models into our existing software,{" "}
					<strong>test and deploy/ship</strong> it
				</JobFeature>
        <JobFeature>Improve and actively work on our <strong>Data Strategy</strong>, manage training and reference data</JobFeature>
				<JobFeature>
					Work closely with our in-house team of Annotators
				</JobFeature>
				<JobFeature>
					Research and evaluate new technologies and papers
				</JobFeature>
				<JobFeature>
					Develop and maintain <strong>CI/CD</strong> pipelines
				</JobFeature>
				<JobFeature>
					Work on custom integration for <strong>enterprise customers</strong>
				</JobFeature>
			</JobRole>
			<JobRequirements>
				<JobFeature>
					You are polyglot in <strong>C/C++</strong> (<strong>CUDA</strong>) and <strong>Python</strong>
				</JobFeature>
				<JobFeature>
					You consider technical problems from a customer perspective and actively engage in forward-looking product development
				</JobFeature>
				<JobFeature>
					You’ve worked with <strong>PyTorch, TensorFlow, Docker and AWS</strong>
				</JobFeature>
				<JobFeature>You write clean code and automate tasks with Bash scripts</JobFeature>
				<JobFeature>You’re always exploring new technologies and sharing your learnings with others</JobFeature>
				<JobFeature>
					You can translate technical jargons to plain English and explain your work to a non-tech-savvy person
				</JobFeature>
				<JobFeature>
					You’ve worked with Scrum before and have a basic understanding of different software development methodologies
				</JobFeature>
				<JobFeature>You take over responsibilities and have a hands-on mentality</JobFeature>
				<JobFeature>You care for data protection and IT security</JobFeature>
			</JobRequirements>
			<h3 className="my-5">What we offer</h3>
			<JobFeature>Exciting, modern tech stacks and work culture that you can actively shape</JobFeature>
			<JobFeature>Work with our young and international team on high-tech solutions and services</JobFeature>
			<JobFeature>Career opportunities as part of a growing startup in an emerging market</JobFeature>
			<JobFeature>Flexible working hours and possibility of remote work</JobFeature>
			<JobFeature>An office at Tabakfabrik Linz - one of the hearts of Austria’s start-up
        scene - with a diverse set of great places for lunch and leisure (e.g.
        Donaulände)</JobFeature>
		</Job>
	)
}

export default JobMachineLearningEngineer
